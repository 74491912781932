import Subsribe from "../../components/newsletter/Subsribe";

export default function HeroFour() {
  return (
    <>
      <section className="hero-section hero-section-2 ptb-100" style={{
        marginBottom: '3rem',
      }}>
        <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div>
          <div className="point animated-point-7"></div>
          <div className="point animated-point-8"></div>
          <div className="point animated-point-9"></div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left ptb-100 text-white" >
                <h1 className="text-white">
                  <span>Potencialize Suas Vendas Online </span>
                  Fature de <span>10K</span> a <span>100K</span> por <span> Mês!</span>
                </h1>
                <p className="lead" style={
                  {
                    marginTop: "3rem",
                  }
                } >
                  Junte-se à comunidade exclusiva para marceneiros e aprenda a vender online, faturando até 100K por mês! Acesse promoções em parafusos, corrediças e MDF. <br />
                  <p style={
                    {
                      marginTop: "1.5rem",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }
                  }>Inscreva-se agora e comece sua transformação!</p>
                </p>


              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
                <img
                  className="img-fluid d-block animation-one"
                  src="assets/img/hero-animation-04.svg"
                  alt="animation "
                />
                <img
                  className="img-fluid d-none d-lg-block animation-two"
                  src="assets/img/hero-animation-01.svg"
                  alt="animation "
                  width="120"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-three"
                  src="assets/img/hero-animation-02.svg"
                  alt="animation "
                  width="120"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-four"
                  src="assets/img/hero-animation-03.svg"
                  alt="animation "
                  width="230"
                />
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}
