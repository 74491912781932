export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${hasBg ? "gray-light-bg" : "background-shape-right"
            } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    Explore Nossas Oportunidades Agora!
                  </h2>
                  <p className="lead" style={{
                    flex: "wrap",
                  }}>
                    O Plano Premium está com um valor promocional especial devido ao lançamento da Plataforma da AcheMarcenaria hoje, 13/07. Os conteúdos de cursos e mentorias estão sendo desenvolvidos, e ao adquirir o plano, você fará parte dessa comunidade em crescimento.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Essencial</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list" style={{
                      textAlign: "left"
                    }}>
                      <li>✔ Acesso a comunidade</li>
                      <li>✔ Acesso a promoções exclusivas</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        R$<span className="price font-weight-bolder">0,00</span>
                      </div>
                    </div>
                    <a href="#contact" className="btn solid-btn" smooth to="#contact">
                      Em Breve!
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5" >
                  <h5 className="mb-2">Premium</h5>
                  <div className="pricing-img mt-4 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list" style={{
                      textAlign: "left"
                    }}>
                      <li>✔ Acesso a comunidade</li>
                      <li>✔ Acesso a promoções exclusivas</li>
                      <li>✔ Acesso a conteúdos exclusivos</li>
                      <li>✔ Acesso a eventos</li>
                      <li>✔ Acesso a cursos</li>
                      <li>✔ Acesso a grupos de networking</li>

                      <li style={{
                        marginTop: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000",
                      }} >✔ 2 Anos de acesso [ LOTE: 1 ]
                        <li
                          style={{
                            fontSize: "12px",
                          }}
                        >Para aqueles que acreditaram no projeto desde o início!</li>
                      </li>

                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        <span
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            color: "#000",
                          }}>12 x R$9,13 </span>
                        <span style={{
                          fontSize: "16px",
                        }}> <br />ou </span>
                        <span className="price font-weight-bolder">R$ 89, 99</span><br />

                      </div>
                    </div>
                    <a href="https://lastlink.com/p/CA74757F8/checkout-payment" className="btn solid-btn" >
                      Junte-se à comunidade!
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Unlimited</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/unlimited.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                    <li>✔ Campanhas ilimitadas</li>
                      <li>✔ Importação via .csv</li>
                      <li>✔ Tenha acesso às Conversas</li>
                      <li>✔ Fluxo de perguntas pré configurado</li>
                      <li>✔ Plano Flexível: Pague apenas por conversa 
                      R$ 0,39 por conversa <br/>
                      <span style={{
                        fontSize: "12px",
                      }}>( Um conversa dura 24 horas)</span></li>
                      <li>✔ Com relatórios</li>
                      <li>✔ API de integração e Webhook</li>
                      <li>✔ Disparos priorizados</li>
                      <span style={{
                        fontSize: "12px",
                      }}>(Intervalo de tempo menor)</span>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        R$<span className="price font-weight-bolder">37,90</span>
                      </div>
                    </div>
                  <div
                    style={{ flex: "1",justifyContent: "center", flexDirection: "column" }}
                  >
                    <a href="#/" className="btn solid-btn" target="_blank"
                  style={{
                    marginBottom: "10px"
                  }}
                  >
                      Assine já!
                    </a>
                  </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section >
      </div >
    </>
  );
}
