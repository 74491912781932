import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                  Acelere suas vendas online com nossas ferramentas e automatizações de processos.
                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      O objetivo é simplificar: precisamos subir os anúncios o quanto antes nas plataformas. A AcheMarcenaria fornecerá arquivos preenchidos que facilitam o cadastro em massa na Shopee, ajudando você a ganhar tempo e acelerar suas vendas online.
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-2.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Os cursos serão diretos ao ponto, sem enrolação, para que você ganhe tempo e dinheiro. Dúvidas podem ser esclarecidas diretamente na comunidade ou durante os encontros online que iremos realizar.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Lembre-se de consultar a comunidade sempre que tiver uma dúvida. Nosso time estará online para ajudar e responder suas perguntas, pois os cursos são diretos ao ponto e não responderão questões no vídeo.
                    </p>
                  </div>
                  <p></p>
                </div>
                {/* <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-4.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Os clientes têm total acesso às conversas do WhatsApp, podendo recebê-las diretamente via webhook para integração com seus CRMs, como HubSpot e RD Station, permitindo uma gestão mais eficaz e personalizada dos leads qualificados.
                    </p>
                  </div>
                  <p></p>
                </div> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/whats-animation.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
