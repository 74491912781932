import React from "react";

const Promo = () => {

  const cards = [
    {
      id: 1,
      title: 'Acesso à Comunidade',
      description: 'Participe de uma comunidade engajada que compartilha conhecimento e experiências na marcenaria, ampliando suas conexões e aprendizado.',
    },
    {
      id: 2,
      title: 'Promoções Exclusivas',
      description: 'Aproveite ofertas especiais que garantem vantagens e descontos únicos para os membros da AcheMarcenaria.',
    },
    {
      id: 3,
      title: 'Conteúdos Exclusivos',
      description: 'Tenha acesso a materiais ricos e conteúdos exclusivos que irão potencializar seu conhecimento e habilidades na marcenaria.',
    },
    {
      id: 5,
      title: 'Eventos',
      description: 'Participe de eventos exclusivos que proporcionam networking e oportunidades de aprendizado diretamente com especialistas do setor.',
    },
    {
      id: 6,
      title: 'Cursos',
      description: 'Acesse cursos especializados que capacitam você a melhorar suas técnicas e aumentar seu faturamento na marcenaria.',
    },
    {
      id: 7,
      title: 'Grupos de Networking',
      description: 'Conecte-se com outros profissionais da área em grupos de networking que promovem troca de experiências e colaboração.',
    }

  ]

  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Quais são os benefícios de se juntar à Comunidade da AcheMarcenaria?</h2>
                <p className="lead">
                  Descubra abaixo as vantagens de utilizar a AcheMarcenaria, sua comunidade e cursos, para transformar sua experiência na marcenaria:
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            {
              cards && cards.map(({
                id,
                title,
                description,
              }) => {
                return (
                  <div key={
                    id
                  } style={{
                    marginBottom: 10,
                  }} className="col-md-4 col-lg-4">
                    <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                      <h5>{title}</h5>
                      <p>{description}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
